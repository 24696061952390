var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('UsersFilters',{on:{"on-filter-change":_vm.loadAsyncData}}),_c('b-table',{staticClass:"table-striped overflow-x-scroll",attrs:{"backend-sorting":"","data":_vm.users.data,"striped":true,"mobile-cards":true,"sticky-header":false},on:{"sort":_vm.onSort}},[_c('b-table-column',{attrs:{"sortable":"","field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"name","label":_vm.$t('companies.name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"job_title","label":_vm.$t('companies.function')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row['job_title'])+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"email","label":_vm.$t('companies.email')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.email)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"locale","label":_vm.$t('companies.language')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.locale)+" ")]}}])}),_c('b-table-column',{attrs:{"sortable":"","field":"locale","label":_vm.$t('companies.user.active.label'),"title":_vm.$t('companies.user.active.actived')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('i',{class:`fas fa-${
                    props.row.active ? 'toggle-on' : 'toggle-off'
                }`,attrs:{"data-toggle":"modal","data-target":'#modalDelete' + props.row.id}}),_c('ModalDeleteSave',{attrs:{"item":_vm.modalDelete(
                        props.row.id,
                        props.row.name,
                        props.row.active
                    )}})]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('companies.actions')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_c('router-link',{staticClass:"inline-flex items-center px-3 py-2 -mt-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",attrs:{"to":`/company/users/${props.row.id}`}},[_c('IconSearch',{staticClass:"h-4 w-4"})],1),_c('router-link',{staticClass:"inline-flex px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",attrs:{"to":`/company/user/${props.row.id}/edit`}},[_c('IconModif',{staticClass:"h-4 w-4"})],1)],1)]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                    props.row.id != _vm.userId &&
                    props.row.user_type != 1 &&
                    !_vm.isUnderImpersonation
                )?_c('div',[_c('button',{staticClass:"btn border-transparent px-3 items-center py-2 ml-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-400",attrs:{"disabled":_vm.getUserImpersonated &&
                        props.row.id == _vm.getUserImpersonated.id},on:{"click":function($event){$event.preventDefault();return _vm.impersonate(props.row.id)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('impersonation.description'))+" ")])])]):_vm._e()]}}])})],1),(_vm.users)?_c('Pagination',{attrs:{"total-results":_vm.users.total,"total-pages":Math.ceil(_vm.users.total / _vm.users.per_page),"per-page":_vm.users.per_page,"current-page":_vm.users.current_page},on:{"pagechanged":_vm.onPageChange}}):_vm._e(),_c('b-loading',{attrs:{"can-cancel":false,"is-full-page":true},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }